import React from "react";
import {connect} from "react-redux";
import Header from "../../components/basics/header/Header";
import ViewContainer from "../../components/basics/view-container/ViewContainer";
import SettingChooser from "./settings/SettingChooser";
import {Route, Switch} from "react-router-dom";
import CustomizeProfileView from "./settings/profile/CustomizeProfileView";
import ChangePasswordView from "./settings/profile/ChangePasswordView";
import GeneralView from "./settings/profile/GeneralView";
import ManageDevicesView from "./settings/devices/ManageDevicesView";
import ManageBuildingsView from "./settings/buildings/manage/ManageBuildingsView";
import withRoles from "../../components/withRoles";
import {Roles} from "../../constans/roles";
import PairLocationView from "./settings/buildings/pair/PairLocationView";
import FeedingCurvesView from "./settings/feeding/curves/FeedingCurvesView";
import EditCurve from "./settings/feeding/curves/EditCurve";
import AddDevicesView from "./settings/devices/AddDevicesView";
import Bridge from "./settings/devices/add/bridge/Bridge";
import DispenserWST from "./settings/devices/add/dispenserWST/DispenserWST";
import DispenserNRF from "./settings/devices/add/dispenserNRF/DispenserNRF";
import ScaleWST from "./settings/devices/add/scaleWST/ScaleWST";
import ClimateWST from "./settings/devices/add/climateWST/ClimateWST";
import Cage from "./settings/devices/add/cage/Cage";
import FeedingPlansView from "./settings/feeding/plans/FeedingPlansView";
import EditPlans from "./settings/feeding/plans/EditPlans";
import FeedingForagesView from "./settings/feeding/forages/FeedingForagesView";
import EditForage from "./settings/feeding/forages/EditForage";
import Shadows from "./settings/debug/Shadows";
import ClimateCurvesView from "./settings/climate/curves/ClimateCurvesView";
import AddClimateCurveView from "./settings/climate/curves/AddClimateCurveView";
import ClimateCurvesViewSK3 from "./settings/climate-sk-3/curves/ClimateCurvesView";
import AddClimateCurveViewSK3 from "./settings/climate-sk-3/curves/AddClimateCurveView";
import CageGains from "./settings/debug/CageGains";
import ManageCage from "./settings/cage/ManageCage";
import ManageDictionary from "./settings/dictionary/ManageDictionary";
import UnitAndTimeSystemView from "./settings/system/UnitAndTimeSystemView";
import ManageDispenserWST from "./settings/dispenserWST/ManageDispenserWST";
import ManageDispenserNRF from "./settings/dispenserNRF/ManageDispenserNRF";
import ManageClimates from "./settings/climate/ManageClimates";
import ManageFarmMap from "./settings/buildings/farm-map/ManageFarmMap";
import ManageSilo from "./settings/silo/ManageSilo";
import Cycle from "./settings/breeding/manage/Cycle";
import AddressDevicesView from "./settings/devices/AddressDevicesView";
import OpenSSHView from "./settings/devices/OpenSSHView";
import BridgeData from "./settings/debug/BridgeData";
import NRFData from "./settings/debug/NRFData/NRFData";
import AssignChambers from "./settings/buildings/assign-chambers/AssignChambers";
import ThermoEye from "./settings/devices/add/thermoeye/ThermoEye";
import DisplayingCage from "./settings/cage/DisplayingCage";
import DevicesSystemView from "./settings/system/DevicesSystemView";
import Terminal from "./settings/debug/Terminal";
import GeneralNotifications from "./settings/notifications/general/GeneralNotifications";
import CageNotifications from "./settings/notifications/cage/CageNotifications";
import ClimateNotifications from "./settings/notifications/climate/ClimateNotifications";
import SiloNotifications from "./settings/notifications/silo/SiloNotifications";
import FeedingNotifications from "./settings/notifications/feeding/FeedingNotifications";
import EventsNotifications from "./settings/notifications/events/EventsNotifications";
import ChangeMQTTPassword from "./settings/profile/ChangeMQTTPassword";
import SubAddressView from "./settings/devices/SubAddressView";
import BackendQueueSuccess from "./settings/debug/BackendQueueSuccess";
import DispenserNRFMulti from "./settings/devices/add/dispenserNRFMulti/DispenserNRFMulti";
import ManageCalibration from "./settings/calibration/ManageCalibration";
import AssignRFIDToStand from "./settings/buildings/assign-rfid-to-stand/AssignRFIDToStand";
import AntennaRFID from "./settings/devices/add/antennaRFID/AntennaRFID";
import MedicineDictionary from "./settings/dictionary/MedicineDictionary";
import Programs from "./settings/debug/Programs";
import RAMToFRAM from "./settings/debug/RAMToFRAM";
import CurrentPrograms from "./settings/debug/CurrentPrograms";
import GraftingProgram from "./settings/breeding/grafting-program/GraftingProgram";
import AddGraftingProgram from "./settings/breeding/grafting-program/AddGraftingProgram";
import GeneralFeedingSettings from "./settings/feeding/general/GeneralFeedingSettings";
import ASCIIClimate from "./settings/debug/ASCIIClimate";
import SystemSchedule from "./settings/system/system-schedules/SystemSchedule";
import AddOrEditWorktypeSystemSchedule from "./settings/system/system-schedules/AddOrEditWorktypeSystemSchedule";
import AddOrEditSystemSchedule from "./settings/system/system-schedules/AddOrEditSystemSchedule";
import ManageDispenserWSTConfiguration from "./settings/dispenserWST/ManageDispenserWSTConfiguration";
import ManageDispenserNRFConfiguration from "./settings/dispenserNRF/ManageDispenserNRFConfiguration";
import ManageClimatesConfiguration from "./settings/climate/ManageClimatesConfiguration";
import ManageCageConfiguration from "./settings/cage/ManageCageConfiguration";
import ManageSiloConfiguration from "./settings/silo/ManageSiloConfiguration";
import TestLoki from "./settings/debug/TestLoki";
import WaterFlowMeter from "./settings/devices/add/waterFlowMeter/WaterFlowMeter";
import TranslateView from "./settings/translate/TranslateView";
import ElectricityMeter from "./settings/devices/add/electricityMeter/ElectricityMeter";
import PigletScaleAdd from "./settings/devices/add/piglet-scale/PigletScaleAdd";
import {withTranslation} from "react-i18next";
import ChainFeeding from './settings/devices/add/chainFeeding/ChainFeeding';
import ManageChainFeeding from './settings/chainFeeding/ManageChainFeeding';
import ManageChainFeedingConfiguration from './settings/chainFeeding/ManageChainFeedingConfiguration';
import PigletScaleEdit from "./settings/devices/add/piglet-scale/PigletScaleEdit";
import UtilityView from './settings/utility/UtilityView';
import WaterCalibration from "./settings/waterCalibration/WaterCalibration";
import FeedingLevels from "./settings/breeding/feeding-levels/FeedingLevels";
import MaintenanceSettingView from "./settings/maintenance/MaintenanceSettingView";
import DeleteFarmDataView from "./settings/delete-farm-data/DeleteFarmDataView";
import ManageClimatesSK3 from "./settings/climate-sk-3/ManageClimates";
import ManageClimatesConfigurationSK3 from "./settings/climate-sk-3/ManageClimatesConfiguration";
import {ClimateSK3} from "./settings/devices/add/climateSK3/ClimateSK3";

export class SettingsView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            history: []
        }
    }

    componentDidMount() {
        // śledzenie ścieżek, które klika użytkownik
        this.props.history.listen((location) => {
            const history = this.state.history.slice(0);
            history.push(location.pathname);
            this.setState({
                history
            })
        })
    }

    getHeader = () => {
        const {t} = this.props;
        try {
            const {location: {pathname}} = this.props;
            let generalKey = pathname.split("/")[3];
            let specifiedKey = pathname.split("/")[4];
            return generalKey && t(`newSettings.headers.${generalKey}`, {returnObjects: true}) ? `${t("settings.settings")}: ${(typeof t(`${generalKey}`, {returnObjects: true}) === "string") ? t(`${generalKey}`) : t(`${generalKey}.${generalKey}`)} ${specifiedKey ? (typeof t(`${specifiedKey}`, {returnObjects: true}) === "string" ? " - " + t(`${specifiedKey}`) : " - " + t(`${specifiedKey}.${specifiedKey}`)) : ""}` : t("settings.settings")
        } catch (e) {
            console.error(e);
            return t("settings.settings");
        }
    };

    /**
     * Metoda wykonująca się po kliknięciu w strzałkę.
     * Jeżeli był to jeden z linków od żywienia lub zarządzania urządzeniami i poprzedni to była edycja, to przenosi to widoku głónego.
     * W przeciwnym wypadku przenosi do wcześniejszej strony.
     */
    onArrowClick = () => {
        let goToSettingsMainPage = false;
        if (this.props.location.pathname.includes("/feeding/curves") ||
            this.props.location.pathname.includes("/feeding/plans") ||
            this.props.location.pathname.includes("/feeding/forages") ||
            this.props.location.pathname.includes("/devices/manage")) {
            let length = this.state.history.length;
            if (this.state.history[length - 2] && (this.state.history[length - 2].includes("/edit") || this.state.history[length - 2].includes("/devices/add"))) {
                goToSettingsMainPage = true;
            }
        }
        if (goToSettingsMainPage) {
            this.props.history.push(`/${this.props.farm}/settings`);
        } else {
            this.props.history.goBack();
        }
    };

    render() {
        return (
            <div className="settings-view">
                <Header
                    text={
                        <>
                            {
                                this.props.history.location.pathname !== `/${this.props.farm}/settings` &&
                                <span><i className={"fas fa-fw fa-arrow-left pointer"}
                                         onClick={this.onArrowClick}/></span>
                            }
                            {this.getHeader()}
                        </>
                    }/>
                <ViewContainer addPaddingForButtons>
                    <Switch>
                        <Route path={`/${this.props.farm}/settings/profile/personalize`}
                               render={() => <CustomizeProfileView/>}/>
                        <Route path={`/${this.props.farm}/settings/profile/changePassword`}
                               render={() => <ChangePasswordView/>}/>
                        <Route path={`/${this.props.farm}/settings/profile/changeMQTTPassword`}
                               render={() => <ChangeMQTTPassword/>}/>
                        <Route path={`/${this.props.farm}/settings/profile/general`} render={() => <GeneralView/>}/>
                        <Route path={`/${this.props.farm}/settings/devices/manage`}
                               render={() => <ManageDevicesView/>}/>
                        <Route path={`/${this.props.farm}/settings/devices/add`} exact
                               render={() => <AddDevicesView/>}/>
                        <Route path={`/${this.props.farm}/settings/devices/add/bridge`} render={() => <Bridge/>}/>
                        <Route path={`/${this.props.farm}/settings/devices/add/dispenserWST`}
                               render={() => <DispenserWST/>}/>
                        <Route path={`/${this.props.farm}/settings/devices/add/dispenserNRF`}
                               render={() => <DispenserNRF/>}/>
                        <Route path={`/${this.props.farm}/settings/devices/add/waterMeter`}
                               render={() => <WaterFlowMeter/>}/>
                        <Route path={`/${this.props.farm}/settings/devices/add/electricityMeter`}
                               render={() => <ElectricityMeter/>}/>
                        <Route path={`/${this.props.farm}/settings/devices/add/weightWST`} render={() => <ScaleWST/>}/>
                        <Route path={`/${this.props.farm}/settings/devices/add/climateWST`}
                               render={() => <ClimateWST/>}/>
                       <Route path={`/${this.props.farm}/settings/devices/add/climateSK3`}
                               render={() => <ClimateSK3/>}/>
                        <Route path={`/${this.props.farm}/settings/devices/add/cage`} render={() => <Cage/>}/>
                        <Route path={`/${this.props.farm}/settings/devices/add/thermoEye`} render={() => <ThermoEye/>}/>
                        <Route path={`/${this.props.farm}/settings/devices/add/dispenserNRFMulti`}
                               render={() => <DispenserNRFMulti/>}/>
                        <Route path={`/${this.props.farm}/settings/devices/add/chainFeeding`}
                               render={() => <ChainFeeding/>}/>
                        <Route path={`/${this.props.farm}/settings/devices/add/antennaRFID`}
                               render={() => <AntennaRFID/>}/>
                        <Route path={`/${this.props.farm}/settings/devices/add/pigletScale`}
                               render={() => <PigletScaleAdd/>}/>
                        <Route path={`/${this.props.farm}/settings/devices/add/pigletScaleEdit`}
                               render={() => <PigletScaleEdit/>}/>
                        <Route path={`/${this.props.farm}/settings/devices/addressing`}
                               render={() => <AddressDevicesView/>}/>
                        <Route path={`/${this.props.farm}/settings/devices/subaddressing`}
                               render={() => <SubAddressView/>}/>
                        <Route path={`/${this.props.farm}/settings/devices/openSSH`} render={() => <OpenSSHView/>}/>
                        <Route path={`/${this.props.farm}/settings/buildings/manage`}
                               render={() => <ManageBuildingsView/>}/>
                        <Route path={`/${this.props.farm}/settings/buildings/map`} render={() => <ManageFarmMap/>}/>
                        <Route path={`/${this.props.farm}/settings/buildings/pairDevice`}
                               render={() => <PairLocationView/>}/>
                        <Route path={`/${this.props.farm}/settings/buildings/assignChambers`}
                               render={() => <AssignChambers/>}/>
                        <Route path={`/${this.props.farm}/settings/feeding/curves`} exact
                               render={() => <FeedingCurvesView/>}/>
                        <Route path={`/${this.props.farm}/settings/feeding/general`} exact
                               render={() => <GeneralFeedingSettings/>}/>
                        <Route path={`/${this.props.farm}/settings/feeding/curves/edit/:index`}
                               render={() => <EditCurve/>}/>
                        <Route path={`/${this.props.farm}/settings/feeding/plans`} exact
                               render={() => <FeedingPlansView/>}/>
                        <Route path={`/${this.props.farm}/settings/feeding/schedules/edit/:index`}
                               render={() => <EditPlans/>}/>
                        <Route path={`/${this.props.farm}/settings/feeding/forages`} exact
                               render={() => <FeedingForagesView/>}/>
                        <Route path={`/${this.props.farm}/settings/feeding/forages/edit/:index`}
                               render={() => <EditForage/>}/>
                        <Route path={`/${this.props.farm}/settings/feeding/f1/manage`}
                               render={props => <ManageDispenserWST {...props}/>} exact/>
                        <Route path={`/${this.props.farm}/settings/feeding/f1/manage/configuration`}
                               render={props => <ManageDispenserWSTConfiguration {...props}/>}/>
                        <Route path={`/${this.props.farm}/settings/feeding/f3f5/manage`}
                               render={props => <ManageDispenserNRF {...props}/>} exact/>
                        <Route path={`/${this.props.farm}/settings/feeding/f3f5/manage/configuration`}
                               render={props => <ManageDispenserNRFConfiguration {...props}/>}/>
                        <Route path={`/${this.props.farm}/settings/feeding/calibration`}
                               render={() => <ManageCalibration/>}/>
                        <Route path={`/${this.props.farm}/settings/buildings/pairDevice`}
                               render={() => <PairLocationView/>}/>
                        <Route path={`/${this.props.farm}/settings/buildings/assignChambers`}
                               render={() => <AssignChambers/>}/>
                        <Route path={`/${this.props.farm}/settings/buildings/assignRFIDToStand`}
                               render={() => <AssignRFIDToStand/>}/>
                        <Route path={`/${this.props.farm}/settings/feeding/curves`} exact
                               render={() => <FeedingCurvesView/>}/>
                        <Route path={`/${this.props.farm}/settings/feeding/curves/edit/:index`}
                               render={() => <EditCurve/>}/>
                        <Route path={`/${this.props.farm}/settings/feeding/schedules`} exact
                               render={() => <FeedingPlansView/>}/>
                        <Route path={`/${this.props.farm}/settings/feeding/schedules/edit/:index`}
                               render={() => <EditPlans/>}/>
                        <Route path={`/${this.props.farm}/settings/feeding/forages`} exact
                               render={() => <FeedingForagesView/>}/>
                        <Route path={`/${this.props.farm}/settings/feeding/forages/edit/:index`}
                               render={() => <EditForage/>}/>
                        <Route path={`/${this.props.farm}/settings/feeding/f1/manage`}
                               render={() => <ManageDispenserWST/>}/>
                        <Route path={`/${this.props.farm}/settings/feeding/f3f5/manage`}
                               render={() => <ManageDispenserNRF/>}/>
                        <Route path={`/${this.props.farm}/settings/feeding/calibration`}
                               render={() => <ManageCalibration/>}/>
                        <Route path={`/${this.props.farm}/settings/feeding/waterCalibration`}
                               render={() => <WaterCalibration/>}/>
                        <Route path={`/${this.props.farm}/settings/weightPanels/manage`}
                               render={props => <ManageSilo {...props}/>} exact/>
                        <Route path={`/${this.props.farm}/settings/weightPanels/manage/configuration`}
                               render={props => <ManageSiloConfiguration {...props}/>}/>
                        <Route path={`/${this.props.farm}/settings/climate/curves`} exact
                               render={() => <ClimateCurvesView/>}/>
                        <Route path={`/${this.props.farm}/settings/climate/curves/add`}
                               render={props => <AddClimateCurveView {...props}/>}/>
                        <Route path={`/${this.props.farm}/settings/climate/curves/edit/:id`}
                               render={props => <AddClimateCurveView {...props}/>}/>
                        <Route path={`/${this.props.farm}/settings/climate/manage`} exact
                               render={props => <ManageClimates {...props}/>}/>
                        <Route path={`/${this.props.farm}/settings/climate-sk3/curves`} exact
                               render={() => <ClimateCurvesViewSK3/>}/>
                        <Route path={`/${this.props.farm}/settings/climate-sk3/curves/add`}
                               render={props => <AddClimateCurveViewSK3 {...props}/>}/>
                        <Route path={`/${this.props.farm}/settings/climate-sk3/curves/edit/:id`}
                               render={props => <AddClimateCurveViewSK3 {...props}/>}/>
                        <Route path={`/${this.props.farm}/settings/climate-sk3/manage`} exact
                               render={props => <ManageClimatesSK3 {...props}/>}/>
                        <Route path={`/${this.props.farm}/settings/climate/manage/configuration`}
                               render={props => <ManageClimatesConfiguration {...props}/>}/>
                        <Route path={`/${this.props.farm}/settings/climate-sk3/manage/configuration`}
                               render={props => <ManageClimatesConfigurationSK3 {...props}/>}/>
                        <Route path={`/${this.props.farm}/settings/cage/manage`}
                               render={props => <ManageCage {...props}/>} exact/>
                        <Route path={`/${this.props.farm}/settings/cage/manage/configuration`}
                               render={props => <ManageCageConfiguration {...props}/>}/>
                        <Route path={`/${this.props.farm}/settings/chainFeeding/manage`}
                               render={props => <ManageChainFeeding {...props}/>} exact/>
                        <Route path={`/${this.props.farm}/settings/chainFeeding/manage/configuration`}
                               render={props => <ManageChainFeedingConfiguration {...props}/>}/>
                        <Route path={`/${this.props.farm}/settings/cage/display`} render={() => <DisplayingCage/>}/>
                        <Route path={`/${this.props.farm}/settings/dictionary/manage`}
                               render={() => <ManageDictionary/>}/>
                        <Route path={`/${this.props.farm}/settings/system/locale`}
                               render={() => <UnitAndTimeSystemView/>}/>
                        <Route path={`/${this.props.farm}/settings/system/devices`}
                               render={() => <DevicesSystemView/>}/>
                        <Route path={`/${this.props.farm}/settings/system/schedules`}
                               render={() => <SystemSchedule/>} exact/>
                        <Route path={`/${this.props.farm}/settings/system/schedules/worktype/add/:index`}
                               render={props => <AddOrEditWorktypeSystemSchedule {...props}/>}/>
                        <Route path={`/${this.props.farm}/settings/system/schedules/worktype/edit/:SetID`}
                               render={props => <AddOrEditWorktypeSystemSchedule {...props}/>}/>
                        <Route path={`/${this.props.farm}/settings/system/schedules/add/:index`}
                               render={props => <AddOrEditSystemSchedule {...props}/>}/>
                        <Route path={`/${this.props.farm}/settings/system/schedules/edit/:SetID`}
                               render={props => <AddOrEditSystemSchedule {...props}/>}/>
                        <Route path={`/${this.props.farm}/settings/breeding/cycleSettings`} render={() => <Cycle/>}/>
                        <Route path={`/${this.props.farm}/settings/breeding/graftingProgram`}
                               render={props => <GraftingProgram {...props}/>} exact/>
                        <Route path={`/${this.props.farm}/settings/breeding/graftingProgram/add/:animalKind`}
                               render={props => <AddGraftingProgram {...props}/>}/>
                        <Route path={`/${this.props.farm}/settings/breeding/graftingProgram/edit/:programID`}
                               render={props => <AddGraftingProgram {...props} edit/>}/>
                        <Route path={`/${this.props.farm}/settings/breeding/feedingLevels`}
                               render={props => <FeedingLevels {...props}/>} exact/>
                        <Route path={`/${this.props.farm}/settings/debug/bridge`} render={() => <BridgeData/>}/>
                        <Route path={`/${this.props.farm}/settings/debug/nrf`} render={() => <NRFData/>}/>
                        <Route path={`/${this.props.farm}/settings/debug/shadows`} render={() => <Shadows/>}/>
                        <Route path={`/${this.props.farm}/settings/debug/cage`} render={() => <CageGains/>}/>
                        <Route path={`/${this.props.farm}/settings/debug/terminal`} render={() => <Terminal/>}/>
                        <Route path={`/${this.props.farm}/settings/debug/climate`} render={() => <ASCIIClimate/>}/>
                        <Route path={`/${this.props.farm}/settings/debug/programs`} render={() => <Programs/>}/>
                        <Route path={`/${this.props.farm}/settings/debug/ramToFram`} render={() => <RAMToFRAM/>}/>
                        <Route path={`/${this.props.farm}/settings/debug/currentPrograms`}
                               render={() => <CurrentPrograms/>}/>
                        <Route path={`/${this.props.farm}/settings/debug/queueSuccess`}
                               render={() => <BackendQueueSuccess/>}/>
                        <Route path={`/${this.props.farm}/settings/debug/loki`} render={() => <TestLoki/>}/>
                        <Route path={`/${this.props.farm}/settings/notifications/general`}
                               render={() => <GeneralNotifications/>}/>
                        <Route path={`/${this.props.farm}/settings/notifications/cage`}
                               render={() => <CageNotifications/>}/>
                        <Route path={`/${this.props.farm}/settings/notifications/climateSettings`}
                               render={() => <ClimateNotifications/>}/>
                        <Route path={`/${this.props.farm}/settings/notifications/siloses`}
                               render={() => <SiloNotifications/>}/>
                        <Route path={`/${this.props.farm}/settings/notifications/feeding`}
                               render={() => <FeedingNotifications/>}/>
                        <Route path={`/${this.props.farm}/settings/notifications/overallEvents`}
                               render={() => <EventsNotifications/>}/>
                        <Route path={`/${this.props.farm}/settings/dictionary/medicines`}
                               render={() => <MedicineDictionary/>}/>
                        <Route path={`/${this.props.farm}/settings/translate/edit`}
                               render={(props) => <TranslateView {...props}/>}/>
                        <Route path={`/${this.props.farm}/settings/utility`}
                               render={(props) => <UtilityView {...props} />}/>
                        <Route path={`/${this.props.farm}/settings/service/maintenance`}
                               render={(props) => <MaintenanceSettingView {...props} />}/>
                        <Route path={`/${this.props.farm}/settings/service/deleteFarmData`}
                               render={(props) => <DeleteFarmDataView {...props} />}/>
                        <Route render={() => <SettingChooser/>}/>
                    </Switch>
                </ViewContainer>
            </div>
        )
    }

}

SettingsView = connect(state => ({
    farm: state.location.farm
}))(SettingsView);
SettingsView = withRoles({roles: [Roles._GENERAL_READ], showComponent: true})(SettingsView);
export default withTranslation()(SettingsView);
